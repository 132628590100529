import { useContext, useState } from 'react';
import ProfilePicDropdownData from '../service/ProfilePicDropdownData';
import PrimaryNavigationData from '../service/PrimaryNavigationData';
import { useLazyQuery } from '@apollo/client';
import {
  getMasterData,
  getGSModelFilters,
  getMetricThreshold,
  getRealTimeOnboardingConfig,
  handleProductPageRequest,
} from '../graphql/queries';
import { helperFunctions } from '../utils/helperFunctions';
import { generateCommonStyles } from '../constants/Constants';
import { ThemeContext } from '../context/ThemeContext';
import { color } from '../theme/Color';

export const useLayoutNavigation = () => {
  const { decodeUserProfile } = helperFunctions();
  const [navigation, setNavigation] = useState([]);
  const [filtersData, setFiltersData] = useState({});
  const [metricsThresholdData, setMetricsThresholdData] = useState(null);
  const [gsppFiltersData, setGSPPFiltersData] = useState([]);
  const [userProfile, setUserProfile] = useState({});
  const [profilePicDropdown, setProfilePicDropdown] = useState(null);
  const [userInitial, setUserInitial] = useState('');
  const [pageRequest, setPageRequest] = useState([]);
  const [realTimeOnboardingConfigData, setRealTimeOnboardingConfigData] =
    useState({});
  const { mode } = useContext(ThemeContext);

  const selectCustomStyles = generateCommonStyles({
    control: (provided) => ({
      ...provided,
      borderColor: `${mode === 'light' ? color._C0C0C0 : color._3a3a3a} !important`,
      boxShadow: 'none',
      minHeight: '32px',
      '&:hover': {
        borderColor: mode === 'light' ? color._3a3a3a : color._545f6f,
      },
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0px 2px',
      width: 'auto',
      height: '32px',
      background: mode === 'light' ? color._FBFCFE : color._272727,
      border: '1px solid',
      borderRadius: '4px',
      flex: 'none',
      order: '0',
      flexGrow: '0',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: mode === 'light' ? '#131313' : '#EFEFEF',
      fontSize: '14px',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.isDisabled
        ? '#999999'
        : mode === 'light'
          ? '#131313'
          : '#EFEFEF',
      fontSize: '14px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '26px',
      padding: '2px 5px 2px 5px',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: mode === 'light' ? '#999999' : '#666666',
      alignSelf: 'center',
      padding: '0px',
      marginTop: '5px',
      '&:hover': {
        color: mode === 'light' ? '#666666' : '#333333',
      },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: mode === 'light' ? color._F3F7FD : '#131216',
      border: mode === 'light' ? '1px solid #3a3a3a' : '1px solid #ffffff40',
      borderRadius: '8px',
      marginTop: '4px',
      zIndex: '9999',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: 'transparent',
      color: state.isSelected
        ? '#0069d7'
        : mode === 'light'
          ? color._131216
          : color._efefef,
      cursor: 'pointer',
      fontSize: '14px',
      padding: '2px 6px',
      '&:hover': {
        backgroundColor: state.isSelected ? 'transparent' : '#2684ff',
      },
    }),
  });

  const getUserRoles = () => {
    const userToken = JSON.parse(
      localStorage.getItem('okta-token-storage')
    )?.accessToken;
    const userProfile = decodeUserProfile(userToken);
    setUserProfile(userProfile);
    setUserInitial(userProfile?.nameInitial);
  };

  const getPrimaryNavigation = () => {
    PrimaryNavigationData.getPrimaryNavigation((navigationList) => {
      setNavigation(navigationList?.primaryNavigation);
    });
  };

  const getProfilePicDropdown = () => {
    ProfilePicDropdownData.getProfilePicDropdown((navigationList) => {
      setProfilePicDropdown(navigationList?.profilePicDropdown);
    });
  };

  const getEndOfMonth = (selectedDate) => {
    const year = selectedDate?.getFullYear();
    const month = selectedDate?.getMonth(); // Months are zero-indexed
    return new Date(Date.UTC(year, month + 1, 0, 23, 59, 59));
  };

  const getCurrentDayOfMonth = () => {
    const currentDate = new Date();
    currentDate.setHours(23, 59, 59, 0); // Set the time to the end of the day
    return new Date(
      currentDate.getTime() - currentDate.getTimezoneOffset() * 60000
    );
  };

  const getStartOfMonth = (selectedDate, flag = false) => {
    const year = selectedDate?.getFullYear();
    const month = selectedDate?.getMonth(); // Months are zero-indexed

    if (flag) {
      return new Date(year, month, 1);
    } else {
      const startDate = new Date(Date.UTC(year, month, 1));
      startDate.setUTCHours(0, 0, 0, 0);
      return startDate;
    }
  };

  const [getFiltersData] = useLazyQuery(getMasterData, {
    onCompleted: (res) => {
      if (res) {
        const {
          getMasterDataFilters: { data, responseCode },
        } = res;
        if (data && responseCode === 200) {
          setFiltersData(data);
        }
      }
    },
  });

  const [getGSPPFiltersData, { loading: gsFiltersLoading }] = useLazyQuery(
    getGSModelFilters,
    {
      onCompleted: (res) => {
        if (res) {
          const {
            getGSModelFilters: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            const filterData = JSON.parse(JSON.stringify(data)); // Deep clone the data to avoid modifying the original object

            // Loop through all the markets
            filterData.forEach((market) => {
              // Loop through all retailers in the current market
              market?.retailers?.forEach((retailer) => {
                // Extract all zones for this retailer
                const allZones = [];
                retailer?.regions?.forEach((region) => {
                  allZones.unshift(...region.zones);
                });

                // Add the "All" region to the retailer's regions
                retailer?.regions?.unshift({
                  regionName: 'All',
                  zones: allZones,
                });

                // Loop through each region and add "All" zone with all districts
                retailer.regions.forEach((region) => {
                  const everyZones = [];
                  const allDistricts = [];
                  region.zones.forEach((zone) => {
                    everyZones.unshift(zone.zoneName);
                    allDistricts.unshift(...zone.districts);
                  });

                  // Add the "All" zone containing all districts within the region
                  region.zones.unshift({
                    zoneName: 'All',
                    districts: allDistricts,
                  });

                  // Add "All" district to each zone containing all districts within the region
                  region.zones.forEach((zone) => {
                    zone.districts.unshift('All');
                  });
                });
              });
            });

            setGSPPFiltersData(filterData);
          }
        }
      },
    }
  );

  const [getMetricThresholdData] = useLazyQuery(getMetricThreshold, {
    onCompleted: (res) => {
      if (res) {
        const {
          getMetricThreshold: { data, responseCode },
        } = res;
        if (data && responseCode === 200) {
          const metricsObj = {
            productName: '',
            productId: 0,
            thresholdMetrics: [],
          };
          data.forEach((item) => {
            metricsObj.productName = item.productName;
            metricsObj.productId = item.productId;
            metricsObj.thresholdMetrics = metricsObj.thresholdMetrics.concat(
              item.thresholdMetrics
            );
          });
          setMetricsThresholdData(metricsObj);
        }
      }
    },
  });

  const [getRealTimeOnboardingConfigData] = useLazyQuery(
    getRealTimeOnboardingConfig,
    {
      onCompleted: (res) => {
        if (res) {
          const {
            getRealTimeOnboardingConfig: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            setRealTimeOnboardingConfigData(data);
          }
        }
      },
    }
  );

  const [handleProductPageRequestData] = useLazyQuery(
    handleProductPageRequest,
    {
      onCompleted: (res) => {
        if (res) {
          const {
            handleProductPageRequest: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            setPageRequest(data);
          } else {
            setPageRequest([]);
          }
        }
      },
    }
  );

  return {
    getPrimaryNavigation,
    getProfilePicDropdown,
    navigation,
    profilePicDropdown,
    getStartOfMonth,
    getEndOfMonth,
    getCurrentDayOfMonth,
    getFiltersData,
    filtersData,
    getGSPPFiltersData,
    gsppFiltersData,
    getMetricThresholdData,
    metricsThresholdData,
    gsFiltersLoading,
    getUserRoles,
    userInitial,
    userProfile,
    selectCustomStyles,
    getRealTimeOnboardingConfigData,
    realTimeOnboardingConfigData,
    handleProductPageRequestData,
    pageRequest,
  };
};
